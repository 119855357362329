/* eslint-disable import/prefer-default-export */

/**
 * возвращаем объект {values: [536.33, 512.98, 489, 464.36, ... ], date: [1, 2, 3, 4,...] }
 * @param {array} data_array - [{…}, {…}, {…},... ]
 * @param {string} name_field
 */
export function getData(data_array, name_field) {
  const output = { values: [], date: [] };
  data_array.forEach((currentItem, index) => {
    const i = index + 1;
    if (i % 12 === 0) {
      // записываем данные через каждый год (через 12 месяцев)
      output.values = [...output.values, currentItem[name_field]];
      output.date = [...output.date, i / 12];
    }
  });
  return output;
}

/**
 * возвращаем объект {totalInterest: {…}, totalPayments: {…}, ... }
 * Внутри объект - результат отработки getData(): {values: [], date: [] }
 * @param {array} input_data - [{count: 1, interestPayment: 557.19, ...}, {…}, {…} ]
 * @param {array} array_names_field - e.g. ["totalInterest", "totalPayments", ... ]
 */
export function getSetsForChart(input_data, array_names_field) {
  const data_object = {};
  array_names_field.forEach(currentItem => {
    data_object[currentItem] = getData(input_data, currentItem);
  });
  return data_object;
}

/**
 * get object{code, title} and code, return `title` from object
 * @param {array} array_of_objects, e.g. [{code: "balance", title: "Balance"}, {…}, {…}]
 * @param {string} name_field, e.g. "balance"
 */
export function getNameField(array_of_objects, name_field) {
  const output = array_of_objects.filter(el => {
    return el.code === name_field;
  });
  return output[0].title;
}

/**
 * возвращаем для графика - конфигурированные данные одного сета (значения и свойства отображения)
 * @param {string} input_label - human name of set , e.g. "Interest Payment"
 * @param {array} input_data, e.g. [536.33, 512.98, 489, ...]
 * @param {object} colors - config-data. Get from configChart
 */
export function getDataset(input_label, input_data, colors) {
  const output = {
    label: input_label,
    data: input_data,
    fill: true,
    backgroundColor: colors.backgroundColor,
    borderColor: colors.borderColor,
    borderWidth: 1,
    hoverBackgroundColor: colors.hoverBackgroundColor,
    hoverBorderColor: colors.hoverBorderColor
  };

  return output;
}

/**
 * возвращаем для графика набор сетов [{label: "Total Interest", data: [], ...}, {…}, {…}, {…}]
 * @param {array} input_data, e.g. [{count: 1, interestPayment: 557.19, ...}, {…}, {…} ]
 * @param {array} input_array, e.g. ["totalInterest", "totalPayments", "interestPayment", "balance"]
 * @param {array} fields, e.g. [{code: "balance", title: "Balance"}, {…}, {…}]
 * @param {array} colors - config-data. Get from configChart
 */
export function getDatasets(input_data, input_array, fields, colors) {
  const output = [];
  input_array.forEach((__currentItem, index) => {
    output[index] = getDataset(
      getNameField(fields, input_array[index]),
      getSetsForChart(input_data, input_array)[input_array[index]].values,
      colors[index]
    );
  });
  return output;
}
