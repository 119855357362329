const configChart = {
  title: "Mortgage Payment Information",
  name_fields: [
    { code: "balance", title: "Balance" },
    { code: "interestPayment", title: "Interest Payment" },
    { code: "principalPayment", title: "Principal Payment" },
    { code: "totalPayment", title: "Total Payment" },
    { code: "totalPayments", title: "Total Payments" },
    { code: "totalInterest", title: "Total Interest" },
    { code: "investorNetWorth", title: "Investor Net Worth" },
    { code: "landlordNetWorth", title: "Landlord Net Worth" },
    { code: "taxesAndMaintenance", title: "Taxes And Maintenance" },
    { code: "totalMonlyPayment", title: "Total Monly Payment" },
    { code: "totalSellingExpenses", title: "Total Selling Expenses" }
  ],
  chart_colors: [
    {
      backgroundColor: "rgba(255,99,132,0.2)",
      borderColor: "rgba(255,99,132,1)",
      hoverBackgroundColor: "rgba(255,99,132,0.4)",
      hoverBorderColor: "rgba(255,99,132,1)"
    },
    {
      backgroundColor: "rgba(75,192,192,0.2)",
      borderColor: "rgba(75,192,192,1)",
      hoverBackgroundColor: "rgba(75,192,192,0.4)",
      hoverBorderColor: "rgba(75,192,192,1)"
    },
    {
      backgroundColor: "rgba(95,237,91,0.2)",
      borderColor: "rgba(95,237,91,1)",
      hoverBackgroundColor: "rgba(95,237,91,0.4)",
      hoverBorderColor: "rgba(95,237,91,1)"
    },
    {
      backgroundColor: "rgba(191,91,237,0.2)",
      borderColor: "rgba(191,91,237,1)",
      hoverBackgroundColor: "rgba(191,91,237,0.4)",
      hoverBorderColor: "rgba(191,91,237,1)"
    }
  ]
};

export default configChart;
