import "./PaymentOneRow.scss";

import { EllipsisOutlined } from "@ant-design/icons";
import { Card } from "antd";
import PropTypes from "prop-types";
import React from "react";

import { getInfo } from "./helper";

export const PaymentOneRow = props => {
  const { data, names, years } = props;

  const gridStyle = {
    width: "25%",
    textAlign: "center",
    padding: "5px"
  };

  function inYears(num) {
    let output = "";
    switch (num) {
      case -1:
        output = "";
        break;
      case 0:
        output = "(in 1 month)";
        break;
      case 1:
        output = "(in 1 year)";
        break;
      default:
        output = `(in ${num} years)`;
    }
    return output;
  }

  return (
    <Card
      title={`${data.month} ${inYears(years)}`}
      hoverable
      extra={<EllipsisOutlined />}
      onClick={() => getInfo(data, inYears(years))}
    >
      <Card.Grid hoverable={false} style={gridStyle}>
        <p>{names[1].label}</p>
        <p>{data.interestPayment}</p>
      </Card.Grid>
      <Card.Grid hoverable={false} style={gridStyle}>
        <p>{names[3].label}</p>
        <p>{data.principalPayment}</p>
      </Card.Grid>
      <Card.Grid hoverable={false} style={gridStyle}>
        <p>{names[7].label}</p>
        <p>{data.landlordNetWorth}</p>
      </Card.Grid>
      <Card.Grid hoverable={false} style={gridStyle}>
        <p>{names[8].label}</p>
        <p>{data.investorNetWorth}</p>
      </Card.Grid>
    </Card>
  );
};

export default PaymentOneRow;

PaymentOneRow.propTypes = {
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  names: PropTypes.arrayOf(PropTypes.object).isRequired,
  years: PropTypes.number.isRequired
};
