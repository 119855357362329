import "../Mortgage.scss";

import { Button, message } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { addMortgage, toggleDrawer } from "../../../actions";
import { saveItemMortgage } from "../helpers";

const AddMortgage = () => {
  const data = useSelector(state => state.state);
  const mortgage_items = useSelector(state => state.mortgageItems);

  const dispatch = useDispatch();

  /**
   * @see ant.message
   * @param {string} callback
   */
  function success() {
    message.success("Mortgage saved successfully", 2);
  }

  /**
   * @see ant.message
   * @param {string} callback
   */
  function error() {
    message.error(
      "Mortgage can't be saved. You already have 10 saved mortgages.",
      2
    );
  }

  /**
   * @param {object} obj - data from state.state
   * @param {saveItemMortgage | null} callback - add {element} to [state.mortgageItems]
   */
  function saveMortgage(obj) {
    if (mortgage_items.length < 10) {
      const tmp = obj;
      const date_arr = [];
      date_arr[0] = new Date().getTime(); // массив, потому что этот параметр не участвует в расчетах. Отличительный тип данных (не "number")
      tmp.create_date = date_arr;
      dispatch(addMortgage(saveItemMortgage(tmp)));
      success();
      dispatch(toggleDrawer(true));
    } else error();
  }

  return <Button onClick={() => saveMortgage(data)}>Save this mortgage</Button>;
};

export default AddMortgage;
