/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-case-declarations */
import { SET_VALUE, UPLOAD_MORTGAGE } from "../actions/types";

const isPercent = true;
export const initialStateWithLimits = {
  // todo how to make it beautiful looking?
  rentValue: { val: 2250, min: 500, max: 4000, step: 50 },
  rentAnnualIncreaseValue: { val: 2.2, min: 0, max: 5, step: 0.1, isPercent },
  propertyValue: { val: 629000, min: 50000, max: 4000000, step: 10000 },
  downPaymentValue: { val: 5, min: 0, max: 50, step: 0.1, isPercent },
  amortizationValue: { val: 25, min: 5, max: 30, step: 1 },
  mortgageRateValue: { val: 2.69, min: 0.01, max: 12, step: 0.01 },
  amountAnnualTaxesValue: { val: 1.1, min: 0, max: 10, step: 0.05, isPercent },
  annualHeatingCostsValue: { val: 500, min: 0, max: 2000, step: 10 },
  buyingHomeValue: { val: 2, min: 0, max: 10, step: 0.1, isPercent },
  sellingHomeValue: { val: 5, min: 0, max: 10, step: 0.1, isPercent },
  maintenanceValue: { val: 1, min: 0, max: 5, step: 0.1, isPercent },
  ownerInsuranceValue: { val: 0.1, min: 0, max: 2, step: 0.1, isPercent },
  rentersInsuranceValue: { val: 250, min: 0, max: 1000, step: 50 },
  rateOfGrowthValue: { val: 2, min: 0, max: 20, step: 0.1, isPercent },
  returnInvestmentValue: { val: 4, min: 0, max: 20, step: 0.1, isPercent },
  rent_or_buy: true
};

// get initialState from more general initialStateWithLimits
// we are using only values in initialState cause min and max are constants
// todo use MIN MAX STEP instead of min max step
const initialState = Object.keys(initialStateWithLimits)
  .map(key => {
    const value = initialStateWithLimits[key];
    const newValue = value.val ? value.val : value;
    return { key, newValue };
  })
  .reduce((accumulator, currentValue) => {
    accumulator[currentValue.key] = currentValue.newValue;
    return accumulator;
  }, {});

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case SET_VALUE:
      const { name } = action.payload;
      // console.log("SET_VALUE(name): ", name, parseFloat(parseFloat(action.payload.value).toFixed(2)));
      // console.log("initialStateWithLimits: ", initialStateWithLimits[name]);
      // console.log("state: ", state[name]);
      // let { value } = action.payload;
      let value = parseFloat(parseFloat(action.payload.value).toFixed(2));
      const newState = { ...state };
      const { min, max } = initialStateWithLimits[name];
      if (min) {
        // todo beautify?
        if (min > value) value = min;
        if (max < value) value = max;
      }
      newState[name] = value;
      return newState;
    case UPLOAD_MORTGAGE: // todo
      const new_state = { ...initialState };
      const upload_mortgage = action.payload.obj;
      console.log("UPLOAD_MORTGAGE: ", upload_mortgage);
      Object.keys(upload_mortgage).forEach(function rk(key) {
        new_state[key] = upload_mortgage[key];
      });
      return new_state;
    default:
      return state;
  }
};
