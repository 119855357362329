import "./RightPart.scss";

import { Switch, Table } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { setValue } from "../../actions";
import { formatToMoney } from "../../helpers/commonHelpers";
import useLogic from "../../logic";
import CopyLink from "../CopyLink/CopyLink";
import AddMortgage from "../Mortgage/AddMortgage/AddMortgage";
import ViewChart from "../ViewChart/ViewChart";
import { usedSets } from "./helper";

const RightPart = () => {
  const payment = useLogic();
  const data = useSelector(s => s.state);
  const {
    monthly_payment,
    monthly_expenses,
    monthly_total,
    columns,
    dataSource,
    rent_or_buy
  } = usedSets(payment, data);
  const dispatch = useDispatch();
  const setCompare = v => {
    dispatch(setValue("rent_or_buy", v));
    window.scrollTo(0, 300);
  };

  return (
    <div className="RightPart div-wrapper">
      <div className="ant-collapse ant-collapse-item-active RightPart-body">
        <h3 className="ant-collapse-header">
          {/* {divElements.summaryBlock.div0.text} */}
          Your <span className="ant-collapse-extra">monthly</span>{" "}
          <span className="ant-collapse-extra">payment</span> will be:{" "}
          <span className="ant-collapse-extra">{monthly_payment}</span>
        </h3>
        <h4>
          additionaly you will need{" "}
          <span className="ant-collapse-extra">{monthly_expenses}</span> for
          another taxes and expenses per month on first year.
        </h4>
        <h4>
          so total is ~{" "}
          <span className="ant-collapse-extra">{monthly_total}</span> per month
        </h4>

        {/* TODO Cash need deepends on  <GeoLocation /> */}

        <div className="wrapper-buttons">
          <AddMortgage />
          {/* <CopyLink /> */}
        </div>

        {/* TODO https://stackoverflow.com/questions/49809158/what-is-the-antd-equivalent-of-bootstrap-4-spacing-utilities */}
        {/* <Checkbox
          style={{ marginLeft: 10 }}
          checked={rent_or_buy}
          onChange={e => setCompare(e.target.checked)}
        >
          Compare to rent the same property
        </Checkbox> */}

        <div
          className="ant-collapse-header"
          style={{ textAlign: "center", padding: "10px 0", margin: "10px 0" }}
        >
          <span style={{ margin: 10 }}>Compare to rent the same property</span>
          <Switch
            checked={rent_or_buy}
            onChange={checked => setCompare(checked)}
          />
        </div>

        {rent_or_buy && (
          <>
            <div style={{ marginLeft: 10 }}>
              {payment.MonthWhenBuyingWillBecameBetter ? (
                <>
                  Landlord networh will be bigger after{" "}
                  {payment.MonthWhenBuyingWillBecameBetter.count} months
                  <br />
                  or{" "}
                  {Math.round(
                    payment.MonthWhenBuyingWillBecameBetter.count / 12
                  )}{" "}
                  years (TODO BETTER)
                  <br />
                  in that day landlordNetWorth will be ={" "}
                  {formatToMoney(
                    payment.MonthWhenBuyingWillBecameBetter.landlordNetWorth
                  )}
                  <br />
                  in that day investorNetWorth will be ={" "}
                  {formatToMoney(
                    payment.MonthWhenBuyingWillBecameBetter.investorNetWorth
                  )}
                </>
              ) : (
                <>Investment is better</>
              )}
            </div>
            <ViewChart
              codes={["investorNetWorth", "landlordNetWorth"]}
              data={payment.paymentSchedule}
              name="Investor Net Worth & Landlord Net Worth"
              type="Line"
            />

            <Table
              className="summary-table paginator-off"
              columns={columns}
              dataSource={dataSource}
              rowKey={record => record.isTitle}
            />
            {/* 
            TODO
            <table className="summary-table paginator-off">
              <tr>
                <th></th>
                <th>Rent</th>
                <th>Buy</th>
              </tr>
              <tr>
                <td>Monthly Payments</td>
                <td>MonthlyProperty expenses</td>
                <td>Monthly savings</td>
              </tr>
              <tr>
                <td>Eve</td>
                <td>Jackson</td>
                <td>94</td>
              </tr>
            </table> */}
          </>
        )}
        {!rent_or_buy && (
          <ViewChart
            codes={["interestPayment", "principalPayment"]}
            data={payment.paymentSchedule}
            name="Interest Payment & Principal Payment"
            type="Bar"
          />
        )}
      </div>
    </div>
  );
};

export default RightPart;
