/* eslint-disable import/prefer-default-export */

/**
 ** возвращаем объект {values: [536.33, 512.98, 489, 464.36, ... ], date: [1, 2, 3, 4,...] }
 * @param {array} data_array, e.g. [{count: 1, interestPayment: 1403.4,...},{},...]
 */
export function getData(data_array) {
  const output = { values: [], date: [] };
  data_array.forEach((_currentItem, index) => {
    const i = index + 1;
    if (i % 12 === 0) {
      // записываем данные через каждый год (через 12 месяцев)
      output.date = [...output.date, i / 12];
    }
  });
  return output;
}

/**
 * return array of numbers [34426.2, 68852.4, 103278.600,...]
 * @param {array} input_data, e.g. [{count: 1, interestPayment: 1403.4,...},{},...]
 * @param {sttring} code_field
 */
export function getSetsForChart(input_data, code_field) {
  const data_object = [];
  input_data.forEach((currentItem, index) => {
    const i = index + 1;
    if (i % 12 === 0) {
      data_object[i / 12 - 1] = currentItem[code_field];
    }
  });
  return data_object;
}

/**
 * get object{code, title} and code, return `title` from object
 * @param {array} array_of_objects, e.g. [{code: "balance", title: "Balance"}, {…}, {…}]
 * @param {string} name_field, e.g. "balance"
 */
export function getNameField(array_of_objects, name_field) {
  const output = array_of_objects.filter(el => {
    return el.code === name_field;
  });
  return output[0].title;
}

/**
 * возвращаем для графика - конфигурированные данные одного сета (значения и свойства отображения)
 * @param {string} input_label - human name of set , e.g. "Interest Payment"
 * @param {array} input_data, e.g. [536.33, 512.98, 489, ...]
 * @param {object} colors - config-data. Get from configChart
 */
export function getDataset(input_label, input_data, colors) {
  const output = {
    label: input_label,
    data: input_data,
    fill: true,
    backgroundColor: colors.backgroundColor,
    borderColor: colors.borderColor,
    borderWidth: 1,
    hoverBackgroundColor: colors.hoverBackgroundColor,
    hoverBorderColor: colors.hoverBorderColor
  };

  return output;
}

/**
 * возвращаем для графика набор сетов [{label: "Mortgage #1", data: [], ...}, {…}, {…}, {…}]
 * @param {array} input_data, e.g. [{count: 1, interestPayment: 557.19, ...}, {…}, {…} ]
 * @param {string} code, e.g. "totalPayments"
 * @param {array} colors - config-data. Get from configChart
 */
export function getDatasets(input_data, code, _a, colors) {
  const output = [];
  input_data.forEach((__currentItem, index) => {
    output[index] = getDataset(
      `Mortgage #${index + 1}`,
      getSetsForChart(input_data[index], code),
      colors[index]
    );
  });
  return output;
}
