import "./Comparison.scss";

import React, { useEffect, useState } from "react";

// import CopyLink from "../CopyLink/CopyLink";
import Mortgages from "./Mortgages";

const Comparison = () => {
  const wlh = window.location.hash;
  const tmp = wlh.slice(wlh.indexOf("?") + 1).split("|");
  const arrMortgage = tmp.filter(function ee(el) {
    return el !== "";
  });

  const [am, setAM] = useState(arrMortgage);

  // update inner value if val is changed
  useEffect(() => {
    const wlh2 = window.location.hash;
    const tmp2 = wlh2.slice(wlh2.indexOf("?") + 1).split("|");
    const arrMortgage2 = tmp2.filter(function ee(el) {
      return el !== "";
    });
    setAM(arrMortgage2);
  }, [wlh]);

  return (
    <div className="Comparison wrapper-div site-card-wrapper">
      <div className="wrapper-btn">
        {/* <CopyLink copyUrl title="copy link with comparison" /> */}
      </div>
      <Mortgages input_data={am} />
    </div>
  );
};

export default Comparison;
