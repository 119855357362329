import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { setValue } from "../../actions";
import config from "../../configs/configCards";
import { initialStateWithLimits } from "../../reducers/baseReducer";
import CardComponent from "./CardComponent";

const CardComponentLogic = props => {
  const { name } = props;
  const { title, text, prefix, suffix } = config[name];
  const value = useSelector(state => state.state[name]);
  const { min, max, step } = initialStateWithLimits[name];
  const dispatch = useDispatch();

  return (
    <CardComponent
      title={title}
      text={text}
      name={name}
      prefix={prefix}
      suffix={suffix}
      step={step}
      min={min}
      max={max}
      val={value}
      onChange={v => dispatch(setValue(name, v))}
    />
  );
};

export default CardComponentLogic;

CardComponentLogic.propTypes = {
  name: PropTypes.string.isRequired
};
