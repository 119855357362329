const defaultPrice = 500000;
const defaultDownPayment = 100000;
const defaultInterestRate = 0.05;
const defaultMonths = 360;
const defaultAdditionalPrincipalPayment = 0;
const defaultRateOfGrowthValue = 0;

// TODO tests + refactoring
class MortgageCalculator {
  constructor() {
    this.totalPrice = defaultPrice;
    this.downPayment = defaultDownPayment;
    this.interestRate = defaultInterestRate;
    this.months = defaultMonths;
    this.additionalPrincipalPayment = defaultAdditionalPrincipalPayment;
    this.rateOfGrowthValue = defaultRateOfGrowthValue;
  }

  calculatePayment() {
    const loanAmount = this.totalPrice - this.downPayment;
    const paymentSchedule = MortgageCalculator.calculatePaymentSchedule(
      loanAmount,
      this.interestRate,
      this.months,
      this.additionalPrincipalPayment
    );
    const piPayment = paymentSchedule.length
      ? paymentSchedule[0].totalPayment
      : 0;
    const downPaymentPercentage = this.downPayment / this.totalPrice;
    let mortgageInsurance = 0;
    if (
      this.mortgageInsuranceEnabled &&
      downPaymentPercentage < this.mortgageInsuranceThreshold
    ) {
      mortgageInsurance = (loanAmount * this.mortgageInsuranceRate) / 12;
    }

    return {
      loanAmount,
      principalAndInterest: piPayment,
      total: piPayment,
      termMonths: this.months,
      paymentSchedule,
      mortgageInsurance
    };
  }

  static calculatePaymentSchedule(
    loanAmount,
    annualRate,
    termMonths,
    additionalPrincipalPayments = 100
  ) {
    const monthlyRate = annualRate / 12;
    const monthlyPayment = MortgageCalculator.calculateMonthlyPIPayment(
      loanAmount,
      annualRate,
      termMonths
    );
    let principal = MortgageCalculator.roundPenny(loanAmount);
    const payments = [];
    let totalInterest = 0;
    let totalPayments = 0;
    let i = 0;
    while (principal > 0 && i < termMonths) {
      const interestPayment = MortgageCalculator.roundPenny(
        principal * monthlyRate
      );
      let principalPayment = MortgageCalculator.roundPenny(
        monthlyPayment - interestPayment + additionalPrincipalPayments
      );
      if (principal > principalPayment) {
        principal = MortgageCalculator.roundPenny(principal - principalPayment);
      } else {
        principalPayment = principal;
        principal = 0;
      }
      const totalPayment = interestPayment + principalPayment;
      totalInterest += interestPayment;
      totalPayments += totalPayment;
      payments[i] = {
        count: i + 1,
        interestPayment,
        totalInterest,
        principalPayment,
        totalPayment,
        totalPayments,
        balance: principal
      };
      i++;
    }

    return payments;
  }

  static calculateMonthlyPIPayment(loanAmount, annualRate, termMonths) {
    const monthlyRate = annualRate / 12;
    const payment =
      (monthlyRate * loanAmount * (1 + monthlyRate) ** termMonths) /
      ((1 + monthlyRate) ** termMonths - 1);
    return this.nextPenny(payment);
  }

  static roundPenny(value) {
    return Math.round(value * 100) / 100;
  }

  static nextPenny(value) {
    return Math.ceil(value * 100) / 100;
  }
}

export default function getMortgageCalculator() {
  const mortgageCalculator = new MortgageCalculator();
  mortgageCalculator.setValue = (name, value) => {
    mortgageCalculator[name] = value;
    return mortgageCalculator;
  };
  return mortgageCalculator;
}
