import "./App.scss";

import { ArrowUpOutlined } from "@ant-design/icons";
import { BackTop, Layout } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { HashRouter, Route } from "react-router-dom";

import Charts from "./Charts/Charts";
import CheckUrl from "./CheckUrl/CheckUrl";
import Comparison from "./Comparison/Comparison";
import CopyLink from "./CopyLink/CopyLink";
// import GeoLocation from "./GeoLocation/GeoLocation";
import HeaderMenu from "./HeaderMenu/HeaderMenu";
import HomePage from "./HomePage/HomePage";
import Payments from "./Payments/Payments";
import RightPart from "./RightPart/RightPart";

const { Header, Content } = Layout;

const App = () => {
  const theme = useSelector(state => state.addState.theme);

  return (
    <div className={`theme-${theme} App`}>
      <CheckUrl />
      <HashRouter>
        <Layout className="container">
          <Header className="header">
            <HeaderMenu />
          </Header>
          <Content className="site-layout">
            {/* TODO Adv here */}
            <Route path="/" exact component={HomePage} />
            <Route path="/payments" exact component={Payments} />
            <Route path="/charts" exact component={Charts} />
            <Route path="/overview" exact component={RightPart} />
            <Route path="/comparison" exact component={Comparison} />
          </Content>
        </Layout>
      </HashRouter>
      <BackTop visibilityHeight={100}>
        <div className="ant-btn ant-btn-circle up">
          <ArrowUpOutlined />
        </div>
      </BackTop>
      <CopyLink />
    </div>
  );
};

export default App;
