import "./Payments.scss";

import { Card, Col, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import configPayments from "../../configs/configPayments";
import { addSuffix, formatToMoney } from "../../helpers/commonHelpers";

const PaymentSummary = props => {
  const { payment } = props;
  const state_values = useSelector(state => state.state);

  const { paymentSummary } = configPayments;
  return (
    <Card className="summary-card ant-collapse ant-collapse-icon-position-left div-wrapper">
      <Row className="txt-right">
        <Col span={14}>{paymentSummary[0]} : </Col>
        <Col span={10}>{formatToMoney(state_values.propertyValue)}</Col>
      </Row>
      <Row className="txt-right">
        <Col span={14}>{paymentSummary[1]} : </Col>
        <Col span={10}>{`${addSuffix(
          state_values.downPaymentValue,
          "%"
        )} = ${formatToMoney(
          (state_values.propertyValue / 100) * state_values.downPaymentValue
        )}`}</Col>
      </Row>
      <Row className="txt-right">
        <Col span={14}>{paymentSummary[2]} : </Col>
        <Col span={10}>{formatToMoney(payment.loanAmount)}</Col>
      </Row>
      <Row className="txt-right">
        <Col span={14}>{paymentSummary[3]} : </Col>
        <Col span={10}>{formatToMoney(payment.total)}</Col>
      </Row>
      <Row className="txt-right">
        <Col span={14}>{paymentSummary[4]} : </Col>
        <Col span={10}>{payment.termMonths / 12}</Col>
      </Row>
      <Row className="txt-right">
        <Col span={14}>{paymentSummary[5]} : </Col>
        <Col span={10}>
          {addSuffix(formatToMoney(payment.mMonthlyExpenses), " / month")}
        </Col>
      </Row>
      <Row className="txt-right">
        <Col span={14}>{paymentSummary[6]} : </Col>
        <Col span={10}>
          {addSuffix(formatToMoney(payment.rMonthlyExpenses), " / month")}
        </Col>
      </Row>
    </Card>
  );
};

export default PaymentSummary;

PaymentSummary.propTypes = {
  payment: PropTypes.objectOf(PropTypes.any).isRequired
};
