import "../Mortgage.scss";

import { Button, message, Modal } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { deleteMortgage } from "../../../actions";
import { getMortgageName } from "../helpers";

const DeleteMortgage = props => {
  const { id_mortgage } = props;
  const mortgage_items = useSelector(state => state.mortgageItems);
  const dispatch = useDispatch();

  const [visible, toggleVisible] = useState(false);

  /**
   * @see ant.message
   * @param {string} callback
   */
  function success() {
    message.success("Mortgage deleted successfully", 2);
  }

  /**
   * @see ant.modal
   * @param {string} callback
   */
  function showConfirm() {
    toggleVisible(!visible);
  }

  /**
   * @param {int} num - state.mortgageItems[num]
   * @param {deleteMortgage} callback - dispatch callback - set value state.mortgageItems.
   */
  function delMortgage(num) {
    dispatch(deleteMortgage(num));
    success();
    showConfirm();
  }

  return (
    <>
      <Modal
        title="Delete mortgage?"
        visible={visible}
        onOk={() => delMortgage(id_mortgage)}
        onCancel={() => showConfirm()}
        okText="YES"
        cancelText="NO"
      >
        {getMortgageName(mortgage_items[id_mortgage])}
      </Modal>
      <Button type="link" onClick={showConfirm}>
        delete
      </Button>
    </>
  );
};

export default DeleteMortgage;

DeleteMortgage.propTypes = {
  id_mortgage: PropTypes.number.isRequired
};
