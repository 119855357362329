/* eslint-disable import/prefer-default-export */
import JsPDF from "jspdf";
// eslint-disable-next-line no-unused-vars
import autoTable from "jspdf-autotable";

import configPayments from "../../configs/configPayments";
import configTextForElements from "../../configs/configTextForElements";
import { addSuffix, formatToMoney } from "../../helpers/commonHelpers";
import { getPaymentShedule } from "../../helpers/paymentHelpers";
import dataimage from "./dataimage";

const { pdfBlock } = configTextForElements;
const { base64, logo } = dataimage;
/**
 * create array of formatted data for doc.autoTable
 * @param {object} obj - data from Mortgage calculator
 * @param {array} arr_captions  - captions from config
 * @returns {array} returned array of string bor table.body
 */
function dataToBody(state_v, obj, arr_captions) {
  const summaryBody = [
    formatToMoney(state_v.propertyValue),
    `${addSuffix(state_v.downPaymentValue, "%")} = ${formatToMoney(
      (state_v.propertyValue / 100) * state_v.downPaymentValue
    )}`,
    formatToMoney(obj.loanAmount),
    formatToMoney(obj.total),
    addSuffix(formatToMoney(obj.termMonths / 12), " years"),
    addSuffix(formatToMoney(obj.mMonthlyExpenses), " / month"),
    addSuffix(formatToMoney(obj.rMonthlyExpenses), " / month")
  ];
  const output = arr_captions.reduce(function rk(str, currentItem, index) {
    const one_str = [arr_captions[index], summaryBody[index]];
    return [...str, one_str];
  }, []);
  return output;
}

/**
 * create Pdf
 * @see JsPDF
 */
export function getData(state_values, payment, paymentSummary, link_to_page) {
  const doc = new JsPDF("p", "mm");
  const firstLink = { rectX: 15, rectY: 120, rectW: 180, rectH: 45 };
  const logoLink = { rectX: 100, rectY: 0, rectW: 60, rectH: 24 };

  doc.autoTable({
    head: [],
    body: dataToBody(state_values, payment, paymentSummary),
    theme: "grid",
    margin: { top: 24 },
    didDrawPage() {
      doc.setTextColor(150);
      doc.setFontSize(14);
      doc.text(pdfBlock.headerText, 40, 14);
      doc.link(logoLink.rectX, logoLink.rectY, logoLink.rectW, logoLink.rectH, {
        url: "https://mortgagio.com/"
      });
      doc.addImage(
        logo[0],
        "PNG",
        logoLink.rectX,
        logoLink.rectY,
        logoLink.rectW,
        logoLink.rectH
      );
      doc.setTextColor(0, 0, 255);
      doc.textWithLink(pdfBlock.link, 20, 90, {
        url: link_to_page
      });
      doc.link(
        firstLink.rectX,
        firstLink.rectY,
        firstLink.rectW,
        firstLink.rectH,
        {
          url: "https://www.coca-cola.com/"
        }
      );
      doc.addImage(
        base64[0],
        "JPEG",
        firstLink.rectX,
        firstLink.rectY,
        firstLink.rectW,
        firstLink.rectH
      );
      doc.addPage("a4", "p");
    }
  });

  const columns = configPayments.generalDataTitle;
  const rows = getPaymentShedule(payment.paymentSchedule);

  doc.autoTable(columns, rows, {
    styles: {
      fontSize: 8
    },
    theme: "grid",
    margin: { top: 24 },
    didDrawPage() {
      doc.setTextColor(150);
      doc.setFontSize(14);
      doc.text(pdfBlock.headerText, 40, 14);
      doc.link(logoLink.rectX, logoLink.rectY, logoLink.rectW, logoLink.rectH, {
        url: "https://mortgagio.com/"
      });
      doc.addImage(
        logo[0],
        "PNG",
        logoLink.rectX,
        logoLink.rectY,
        logoLink.rectW,
        logoLink.rectH
      );
    }
  });
  doc.save(pdfBlock.nameFile);
}
