import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { setValue } from "../../actions";

const CheckUrl = () => {
  const dispatch = useDispatch();
  const [marker, count] = useState(true);
  const separator_question = window.location.href.indexOf("?");
  const data_from_url =
    separator_question === -1
      ? ""
      : window.location.href.substr(separator_question + 1);

  /**
   * if url has values (without "{}" ) after "?" - changes state.state
   * or - URL
   * @param {string} data  - e.g. rentValue:3900,propertyValue:1960000,...
   * @param {setValue} callback - dispatch callback - set value state.state.
   * @param marker - execute CheckURL once
   */
  function checkUrl(data) {
    if (marker) {
      if (data.length !== 0 && data.indexOf("{") === -1) {
        const array_values = data.split(",");
        array_values.forEach(oneString => {
          const toState = oneString.split(":");
          dispatch(setValue(toState[0], parseFloat(toState[1])));
        });
        count(false);
      }
    }
  }

  return <div>{checkUrl(data_from_url)}</div>;
};

export default CheckUrl;
