import "./Comparison.scss";

import { Card, Input, InputNumber } from "antd";
import PropTypes from "prop-types";
import React from "react";

import { initialStateWithLimits } from "../../reducers/baseReducer";
import { returnFormatter, returnParcer } from "../CardComponent/helpers";

const NumericInput = props => {
  const { value, name, onChange, index, prefix, suffix } = props;
  const { min, max, step } = initialStateWithLimits[name];
  return (
    <>
      <Card.Grid hoverable={false} className="trick-input">
        <InputNumber
          className={`be-input ${prefix.length > 0 ? "left-25" : ""}`}
          value={value}
          name={name}
          step={step}
          min={min}
          max={max}
          formatter={valIn => returnFormatter(valIn, prefix, suffix)}
          parser={valIn => returnParcer(valIn, prefix, suffix)}
          onChange={e => onChange({ index, name, new_value: e })}
        />
        <Input addonBefore={prefix} addonAfter={suffix} />
      </Card.Grid>
    </>
  );
};

export default NumericInput;

NumericInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string
};

NumericInput.defaultProps = {
  prefix: "",
  suffix: ""
};
