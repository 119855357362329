import "./HeaderMenu.scss";

import {
  BarChartOutlined,
  CalculatorOutlined,
  DesktopOutlined,
  DollarOutlined
} from "@ant-design/icons";
import { Menu, Tooltip } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import configHeaderMenu from "../../configs/configHeaderMenu";
import DrawerComponentLeft from "../DrawerComponentLeft/DrawerComponentLeft";

const HeaderMenu = () => {
  const { headermenu } = configHeaderMenu;
  const showMenu = useSelector(state => state.addState.showMenu);
  const [currItem, setCurrItem] = useState(window.location.hash || "#/");

  /**
   * get the event element and set it to the current
   * @param {event} e - the event that happened
   * @param {setCurrItem} callback - set current button in HeaderMenu.
   */
  function handleClick(e) {
    setCurrItem(e.key);
  }

  /**
   * Returns the Component-icon for menu's item
   * @param {number} num
   * @returns {<Component />} icon from library ant
   */
  function getIcon(num) {
    const arr = [
      <CalculatorOutlined />,
      <DollarOutlined />,
      <BarChartOutlined />,
      <DesktopOutlined />
    ];
    return arr[num];
  }

  /**
   * Returns all buttons for HeaderMenu
   * @param {array} arr - e.g.[{ id: 1, name: "calculator", link: "#/", addClass: "", icon: 0 },...]
   * @returns {<Menu />} Menu markup from library ant
   */
  function renderMenu(arr) {
    return arr.map(item => {
      return (
        <Menu.Item to={item.link} key={item.link} className={item.addClass}>
          <Tooltip placement="bottom" title={item.name}>
            <a href={item.link} className="mini-a">
              {getIcon(item.icon)}&nbsp;
              <span className="span-block">{item.name}</span>
            </a>
          </Tooltip>
        </Menu.Item>
      );
    });
  }

  return (
    <div className={`${showMenu !== true ? "hide-menu " : ""} HeaderMenu`}>
      <div className="logo" />
      <Menu
        onClick={e => handleClick(e)}
        selectedKeys={[currItem]}
        mode="horizontal"
      >
        {renderMenu(headermenu)}
      </Menu>
      <DrawerComponentLeft />
    </div>
  );
};

export default HeaderMenu;
