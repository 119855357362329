/* eslint-disable import/prefer-default-export */
import { Modal } from "antd";
import React from "react";

import configPayments from "../../configs/configPayments";

function getTitle(arr_of_obj, datakey) {
  const output = arr_of_obj.filter(obj => {
    return obj.dataKey === datakey;
  });
  return output[0].header;
}

function oneRow(obj, names) {
  let output = "";
  Object.entries(obj).forEach(([key, value]) => {
    if (key !== "month")
      output += `<div>${getTitle(names, key)}:</div><div>${value}</div>`;
  });
  return output;
}

export function getInfo(data, in_year) {
  Modal.info({
    title: `${data.month} ${in_year}`,
    content: (
      <div
        className="wrapper-modal"
        dangerouslySetInnerHTML={{
          __html: oneRow(data, configPayments.generalDataTitle)
        }}
      />
    ),
    onOk() {}
  });
}
