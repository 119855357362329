/* eslint-disable import/prefer-default-export */

/**
 * create link with params from URL (localhost:3000/#/?) and
 * data from:
 * - state (rentValue:1000,propertyValue:50000...)
 * or - data
 * @param {array|string} data  - state.state or data
 * @returns {navigator.clipboard} new URL is in clipboard
 */
export function copyURL(data) {
  console.log("copyURL", data);
  const is_array = Array.isArray(data);
  const separator = window.location.href.indexOf("#");
  const res = window.location.href.substr(0, separator + 2);
  let result = `${res}`;
  if (is_array) {
    result += "comparison?";
    data.forEach(key => {
      result += `${key}|`;
    });
  } else {
    result += "?";
    Object.keys(data).forEach(key => {
      result =
        typeof data[key] === "number"
          ? `${result + key}:${data[key]},`
          : result;
    });
  }
  return result.substring(0, result.length - 1);
}
