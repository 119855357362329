import "./PaymentsList.scss";

import { Switch } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { getStorage, setStorage } from "../../helpers/commonHelpers";
import PaymentOneRow from "../PaymentOneRow/PaymentOneRow";

const PaymentsList = props => {
  const { data } = props;
  const [kind_of_view, setKindOfView] = useState(getStorage("dateInterval"));

  function getRows(onerow, interval) {
    const frequency = interval === "years" ? 12 : 1;
    let output = [];
    onerow.rows.forEach((el, index) => {
      const i = index;
      let years = -1;
      if (i % frequency === 0 && frequency === 12) years = i / frequency;
      if (i % 12 === 0 && frequency === 1) years = i / 12;

      if (i % frequency === 0)
        output = [
          ...output,
          <PaymentOneRow
            key={i}
            data={el}
            names={onerow.columns}
            years={years}
          />
        ];
    });
    return output;
  }

  function onChangeView(checked) {
    setStorage("dateInterval", checked ? "months" : "years");
    setKindOfView(getStorage("dateInterval"));
  }

  function defaultChecked() {
    const output = kind_of_view === "years" ? 0 : 1;
    return output;
  }

  return (
    <div className="wrapper-list">
      <div className="date-view-wrapper">
        <div>Years</div>
        <Switch
          className="date-view"
          defaultChecked={defaultChecked()}
          onChange={onChangeView}
        />
        <div>Months</div>
      </div>
      <div className="list-cards">{getRows(data, kind_of_view)}</div>
    </div>
  );
};

export default PaymentsList;

PaymentsList.propTypes = {
  data: PropTypes.objectOf(PropTypes.array).isRequired
};
