/* eslint-disable import/prefer-default-export */
import { Button } from "antd";
import React from "react";

import {
  addSuffix,
  formatToMoney,
  jsonParce
} from "../../helpers/commonHelpers";
import CreatePdf from "../CreatePdf/CreatePdf";
import NumericInput from "./NumericInput";

/**
 *
 * @param {*} input_data
 * @param {*} index
 * @param {*} name_field
 * @param {*} toExecute
 */
export function returnInputElement(
  input_data,
  index,
  name_field,
  toExecute,
  origin
) {
  const original_data =
    name_field === "pdfAction"
      ? input_data[index]
      : jsonParce(input_data[index]);
  const downpayment_money = formatToMoney(
    (original_data.propertyValue / 100) * original_data.downPaymentValue
  );

  const switch_output = {
    propertyValue: () => (
      <NumericInput
        value={original_data.propertyValue}
        onChange={toExecute}
        index={index}
        name="propertyValue"
        prefix="$"
      />
    ),
    downpayment: () => (
      <>
        <NumericInput
          value={original_data.downPaymentValue}
          onChange={toExecute}
          index={index}
          name="downPaymentValue"
          suffix="%"
        />
        {downpayment_money}
      </>
    ),
    termMonths: () => (
      <>
        <NumericInput
          value={original_data.amortizationValue}
          onChange={toExecute}
          index={index}
          name="amortizationValue"
          suffix="years"
        />
      </>
    ),
    goTo: () => <Button onClick={() => toExecute(index)}>details</Button>,
    copyMortgage: () => (
      <Button className="create-link" onClick={() => toExecute(index)}>
        copy Mortgage
      </Button>
    ),
    pdfAction: () => (
      <CreatePdf payment={original_data} link={jsonParce(origin[index])} />
    )
  };
  const output = switch_output[name_field]();
  return output;
}

export function formatMoney(val, suff) {
  const output =
    typeof suff === "string"
      ? addSuffix(formatToMoney(val), suff)
      : formatToMoney(val);
  return output;
}

export function lookForBigger(original_array, name_field) {
  let output = "";
  if (original_array[0][name_field] > original_array[1][name_field])
    output = "first";
  if (original_array[0][name_field] < original_array[1][name_field])
    output = "second";
  return output;
}
