import { combineReducers } from "redux";

import addReducer from "./addReducer";
import baseReducer from "./baseReducer";
import mortgageReducer from "./mortgageReducer";

export default combineReducers({
  state: baseReducer,
  addState: addReducer,
  mortgageItems: mortgageReducer
});
