/* eslint-disable jsx-a11y/click-events-have-key-events */
import "./DrawerComponentLeft.scss";

import { UnorderedListOutlined } from "@ant-design/icons";
import { Badge, Button, Divider, Drawer } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { toggleDrawer } from "../../actions";
import configTFE from "../../configs/configTextForElements";
import ComparisonTable from "../ComparisonTable/ComparisonTable";
import SwitchTheme from "../SwitchTheme/SwitchTheme";

const DrawerComponentLeft = () => {
  const dispatch = useDispatch();
  const { drawerBlock } = configTFE;
  const show_drawer = useSelector(state => state.addState.drawer);
  const count_badge = useSelector(state => state.mortgageItems.length);

  /**
   * toggle visible for Left Drawer
   * @param {boolean} val - flag
   * @param {toggleDrawer} callback - dispatch callback - set value state.addState.drawer.
   */
  function setVisible(val) {
    dispatch(toggleDrawer(val));
  }

  return (
    <div className="DrawerComponentLeft">
      <Badge count={count_badge}>
        <Button
          type="primary"
          className="ant-menu-item ant-menu-item-only-child helper"
          icon={<UnorderedListOutlined />}
          onClick={() => setVisible(true)}
        >
          {/* {buttons.commonButtons.helper.text} */}
        </Button>
      </Badge>
      <Drawer
        title={drawerBlock.title4.text}
        placement="right"
        closable
        onClose={() => setVisible(false)}
        visible={show_drawer}
        getContainer={false}
        style={{ position: "absolute" }}
        width={540}
      >
        {process.env.NODE_ENV !== "production" && 1 === 0 && (
          <div>
            <Divider orientation="left">{drawerBlock.title2.text}</Divider>
            <SwitchTheme />
          </div>
        )}
        {/* <Divider orientation="left">{drawerBlock.title4.text}</Divider> */}
        <ComparisonTable />
        <Divider />
      </Drawer>
    </div>
  );
};

export default DrawerComponentLeft;
