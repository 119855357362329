import "./Comparison.scss";

import PropTypes from "prop-types";
import React from "react";

import { formatMoney, lookForBigger } from "./helper";

const OneRowStatic = props => {
  const { input_data, name_field, title } = props;
  return (
    <>
      {title !== "" && (
        <tr className="ant-table-row">
          <td className="ant-table-cell description" colSpan={2}>
            {title}
          </td>
        </tr>
      )}
      <tr
        className={`ant-table-row tr-color-${lookForBigger(
          input_data,
          name_field
        )}`}
      >
        <td className="ant-table-cell">
          {formatMoney(input_data[0][name_field])}
        </td>
        <td className="ant-table-cell">
          {formatMoney(input_data[1][name_field])}
        </td>
      </tr>
    </>
  );
};

export default OneRowStatic;

OneRowStatic.propTypes = {
  input_data: PropTypes.arrayOf(PropTypes.object).isRequired,
  name_field: PropTypes.string.isRequired,
  title: PropTypes.string
};

OneRowStatic.defaultProps = {
  title: ""
};
