import "./Charts.scss";

import React from "react";

import useLogic from "../../logic";
import ViewChart from "../ViewChart/ViewChart";

const Charts = () => {
  // Receive data from Mortgage.Calculator
  const payment = useLogic();
  const arrayPaymentShedule = payment.paymentSchedule;

  return (
    <div className="Charts">
      <ViewChart
        codes={["interestPayment", "principalPayment"]}
        data={arrayPaymentShedule}
        name="Interest Payment & Principal Payment"
        type="Bar"
      />
      <ViewChart
        codes={["totalInterest", "totalPayments"]}
        data={arrayPaymentShedule}
        name="Total Interest & Total Payments"
        type="Bar"
      />
      <ViewChart
        codes={["totalInterest", "totalPayments", "interestPayment", "balance"]}
        data={arrayPaymentShedule}
        name="Total Interest & Total Payments"
        type="Line"
      />
    </div>
  );
};

export default Charts;
