import "./HomePage.scss";

import { Col, Row } from "antd";
import React from "react";

import LeftPart from "../LeftPart/LeftPart";
import RightPart from "../RightPart/RightPart";

const HomePage = () => {
  return (
    <div className="HomePage wrapper-div">
      <Row>
        <Col lg={12} md={12} sm={24} className="ant-col-24">
          <LeftPart />
        </Col>
        <Col lg={12} md={12} className="laptop">
          <RightPart />
        </Col>
      </Row>
    </div>
  );
};

export default HomePage;
