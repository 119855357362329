import configPayments from "../../configs/configPayments";

const { arrayMonths } = configPayments;

/**
 * Returns formatted string: pref + valF + suff
 * valF, pref, suff - newValue, preffix, suffix
 */
export function returnFormatter(valF, prefix, suff) {
  let formatter;
  if (prefix === "$") {
    // https://stackoverflow.com/a/16233919
    /* return valF.toLocaleString("en-US", {
      style: "currency",
      currency: "USD"
    }); */
    const tmp = `${valF}`.replace(/[^\d.-]/g, "");
    formatter = `${Number(tmp).toFixed(0)}`.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
  } else if (suff === "%") {
    // formatter = `${Number(valF).toFixed(2)}`;
    formatter = valF;
  } else {
    formatter = `${Number(parseInt(valF, 10) || 0).toFixed(0)}`;
  }
  return formatter;
}

export function returnFormatterPercent(valF) {
  let abs = parseFloat(valF);
  abs = abs.toFixed(2);
  const formatter = `${abs} %`;
  return formatter;
}

// return human year (e.g. 2020, 2021)
export function xYear(v) {
  const today = new Date();
  const fullYear = today.getFullYear();
  const result = fullYear + parseInt(v, 0);
  return result;
}
// return human month (e.g. Apr, Sep)
export function xMonth() {
  const today = new Date();
  const month = today.getMonth();
  const result = arrayMonths[month];
  return result;
}

export function returnParcer(valP, prefix, suffix) {
  let parcer;
  if (prefix === "$") {
    parcer = valP.replace(/\$\s?|(,*)/g, "");
  } else {
    parcer = valP.replace(`${suffix}`, "");
  }
  parcer = parcer.replace(/\s+/g, "");
  return parcer;
}

export function returnParcerPercent(valP) {
  let parcer = valP.replace("%", "");
  parcer = parcer.replace(/\s+/g, "");
  parcer = parcer.toFixed(2);
  return parcer;
}

export function formattedData(valF, prefix, suffix) {
  const formatter = new Intl.NumberFormat("en-EN").format(valF);
  const result = `${prefix || ""}${formatter}${suffix || ""}`;
  return result;
}

export function formattedDataPercent(valF) {
  const formatter = new Intl.NumberFormat("en-EN").format(valF);
  const result = `${formatter} %`;
  return result;
}

/**
 * @param {number} value - value in number
 * @param {number} percent - %
 * @returns {number}  % of value
 */
export function returnMoneyValue(value, percent) {
  let result = (percent / 100) * value;
  // WTF ???
  if (typeof value === "object")
    result = (percent / 100) * parseFloat(value.target.value);
  return result;
}

export function returnPercentValue(a, b) {
  const result = (a / b) * 100;
  return result;
}
