import { useSelector } from "react-redux";

import { returnMoneyValue } from "./components/CardComponent/helpers";
import { jsonParce } from "./helpers/commonHelpers";
import analizeForInvestment from "./lib/investmentComparasion";
import getMortgageCalculator from "./lib/mortgage";

/**
 * todo use it only once after each param is chnage din the state
 * @param {C} my_str
 */
export default function useLogic(my_str) {
  const data = useSelector(s => s.state);

  const totalPrice = data.propertyValue;
  const downPayment = returnMoneyValue(data.downPaymentValue, totalPrice);
  const interestRate = data.mortgageRateValue / 100;
  const months = data.amortizationValue * 12;
  const taxRate = data.amountAnnualTaxesValue / 100;
  const insuranceRate = data.ownerInsuranceValue / 100;

  const annualMaintenanceValue = totalPrice * (data.maintenanceValue / 100);
  const annualOwnerInsuranceValue =
    totalPrice * (data.ownerInsuranceValue / 100);
  const { annualHeatingCostsValue } = data;
  const annualTaxValue = taxRate * totalPrice;

  let payment = "";
  // TODO tests + refactoring !!!
  if (typeof my_str !== "string") {
    payment = getMortgageCalculator()
      .setValue("totalPrice", totalPrice)
      .setValue("downPayment", downPayment)
      .setValue("interestRate", interestRate)
      .setValue("months", months)
      // .setValue("taxRate", taxRate)
      .setValue("insuranceRate", insuranceRate)
      .calculatePayment();
  } else {
    const json = jsonParce(my_str);

    payment = getMortgageCalculator()
      .setValue("totalPrice", json.propertyValue)
      .setValue(
        "downPayment",
        returnMoneyValue(json.downPaymentValue, json.propertyValue)
      )
      .setValue("interestRate", json.mortgageRateValue / 100)
      .setValue("months", json.amortizationValue * 12)
      .setValue("taxRate", json.amountAnnualTaxesValue)
      .setValue("insuranceRate", json.ownerInsuranceValue / 100)
      .calculatePayment();
  }

  // tax + maint + insurance + heating
  const mMonthlyExpenses =
    (annualTaxValue +
      annualMaintenanceValue +
      annualOwnerInsuranceValue +
      annualHeatingCostsValue) /
    12;
  payment.mMonthlyExpenses = mMonthlyExpenses;

  const rMonthlyExpenses = data.rentersInsuranceValue;
  payment.rMonthlyExpenses = rMonthlyExpenses;

  analizeForInvestment(data, payment);
  return payment;
}
