/* eslint-disable import/prefer-default-export */
import React from "react";

import configPayments from "../configs/configPayments";
import { formatToMoney } from "./commonHelpers";

const { arrayMonths } = configPayments;

/**
 * get num and returned humanDate: today + <number> months
 * @param {integer} timestamp  - any number
 * @returns {string} returned formatted string. E.g. Feb-2022
 */
export function getDay(timestamp) {
  const current_date = new Date(timestamp);
  const num_day = current_date.getDate();
  const num_month = current_date.getMonth() % 12;
  const num_year =
    Math.floor((current_date.getMonth() / 12) % 12) +
    current_date.getFullYear();
  const new_date = `${num_day} ${arrayMonths[num_month]} ${num_year}`;
  return new_date;
}

/**
 * get num and returned humanDate: today + <number> months
 * @param {integer} plus_month  - any number
 * @returns {string} returned formatted string. E.g. Feb-2022
 */
const getHumanDate = plus_month => {
  const current_date = new Date();
  const num_month = (current_date.getMonth() + plus_month) % 12;
  const num_year =
    Math.floor(((current_date.getMonth() + plus_month) / 12) % 12) +
    current_date.getFullYear();
  const new_date = `${arrayMonths[num_month]} ${num_year}`;
  return new_date;
};

function formatValue(value, key) {
  const output =
    typeof key !== "string"
      ? formatToMoney(value)
      : `#${value}: ${getHumanDate(value)}`;
  return output;
}

function getOneString(data_object) {
  const output = {
    month: formatValue(data_object.count, "count"),
    interestPayment: formatValue(data_object.interestPayment),
    totalInterest: formatValue(data_object.totalInterest),
    principalPayment: formatValue(data_object.principalPayment),
    totalPayment: formatValue(data_object.totalPayment),
    totalPayments: formatValue(data_object.totalPayments),
    balance: formatValue(data_object.balance),
    landlordNetWorth: formatValue(data_object.landlordNetWorth),
    investorNetWorth: formatValue(data_object.investorNetWorth)
  };
  return output;
}

export function getPaymentShedule(data_array) {
  let output = [];
  data_array.reduce((__str, currentItem) => {
    output = [...output, getOneString(currentItem)];
    return output;
  }, []);
  return output;
}
