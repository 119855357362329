import { SET_VALUE } from "./types";

export default function setValue(name, value) {
  console.log("setValue(name, value)", name, value)
  return {
    type: SET_VALUE,
    payload: {
      name,
      value
    }
  };
}
