/* eslint-disable no-case-declarations */
import {
  SET_LOCATION,
  SET_THEME,
  TOGGLE_DRAWER,
  TOGGLE_MENU
} from "../actions/types";

const initialState = {
  country: { country: "Canada", section: "Alberta" },
  theme: "light",
  drawer: false,
  showMenu: false
};

// todo: yeah it's can be better: for now quick solution.
const getDeepCopy = obj => {
  return JSON.parse(JSON.stringify(obj));
};

// always return deep copy of initialState
export const getInitialState = () => {
  return getDeepCopy(initialState);
};

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case SET_THEME:
      console.log("SET_THEME: ", action.payload.value);
      const state_st = getDeepCopy(state);
      state_st.theme = action.payload.value;
      return state_st;
    case SET_LOCATION:
      console.log("SET_LOCATION: ", action.payload.value);
      const state_sl = getDeepCopy(state);
      state_sl.country = action.payload.value;
      return state_sl;
    case TOGGLE_DRAWER:
      console.log("TOGGLE_DRAWER: ", action.payload.value);
      const state_td = getDeepCopy(state);
      state_td.drawer = action.payload.value;
      return state_td;
    case TOGGLE_MENU:
      console.log("TOGGLE_MENU: ", action.payload.value);
      const state_tm = getDeepCopy(state);
      state_tm.showMenu = action.payload.value;
      return state_tm;
    default:
      return state;
  }
};
