import { returnMoneyValue } from "../components/CardComponent/helpers";

// TODO tests + refactoring
export default function analizeForInvestment(data, payment) {
  const costOfBuying = data.buyingHomeValue / 100; // %
  const costOfBuyingValue = costOfBuying * data.propertyValue;
  const costOfSelling = data.sellingHomeValue / 100; // %
  /**
   * Percent from original propertyValue and current propertyValue
   * we need this cause home price will be different with a time
   * @param {number} currentPopertyValue
   */
  const totalSellingExpenses = currentPopertyValue => {
    const costOfSellingValue = costOfSelling * currentPopertyValue;
    return costOfSellingValue;
  };

  const rateOfGrowthValue = data.rateOfGrowthValue / 100;
  const rentAnnualIncreaseValue = data.rentAnnualIncreaseValue / 100;

  const totalPrice = data.propertyValue;
  const initialLandlordNetWorth =
    costOfBuyingValue + returnMoneyValue(data.downPaymentValue, totalPrice);
  const initialInvestorNetWorth = initialLandlordNetWorth;

  const periodInterest = 1 + data.returnInvestmentValue / 12 / 100;

  let sjjsjs = true;

  // eslint-disable-next-line no-param-reassign
  payment.paymentSchedule.forEach((el, idx) => {
    const monthCount = el.count;
    const yearCount = monthCount / 12;
    const currentPopertyValue =
      totalPrice * (1 + rateOfGrowthValue) ** yearCount;
    el.currentPopertyValue = currentPopertyValue;
    el.totalSellingExpenses = totalSellingExpenses(currentPopertyValue);
    const landlordNetWorth =
      currentPopertyValue - el.balance - el.totalSellingExpenses;
    el.landlordNetWorth = landlordNetWorth;

    const prevInvestorNetWorth =
      idx > 0
        ? payment.paymentSchedule[idx - 1].investorNetWorth
        : initialInvestorNetWorth;

    if (idx > 0) {
      const thisYearPrice =
        el.count % 12 === 0
          ? currentPopertyValue
          : payment.paymentSchedule[idx - 1].thisYearPrice;
      el.thisYearPrice = thisYearPrice;
    } else {
      el.thisYearPrice = totalPrice;
    }

    const monthlyMaintenanceValue =
      (el.thisYearPrice * (data.maintenanceValue / 12)) / 100;
    const monthlTaxes =
      (el.thisYearPrice * (data.amountAnnualTaxesValue / 12)) / 100;
    const monthlOwnerInsuranceValue =
      (el.thisYearPrice * (data.ownerInsuranceValue / 12)) / 100;
    const monthlOtherExpenses = data.annualHeatingCostsValue / 12;

    const taxesAndMaintenance =
      monthlyMaintenanceValue +
      monthlTaxes +
      monthlOwnerInsuranceValue +
      monthlOtherExpenses;
    el.taxesAndMaintenance = taxesAndMaintenance;

    const totalMonlyPayment = el.totalPayment + taxesAndMaintenance;

    el.totalMonlyPayment = totalMonlyPayment;

    const currentRentValue =
      data.rentValue * (1 + rentAnnualIncreaseValue) ** yearCount;

    if (idx > 0) {
      const thisYearRentValue =
        el.count % 12 === 0
          ? currentRentValue
          : payment.paymentSchedule[idx - 1].thisYearRentValue;
      el.thisYearRentValue = thisYearRentValue;
    } else {
      el.thisYearRentValue = data.rentValue;
    }

    const monthlyRenterExpenses = data.rentValue + data.rentersInsuranceValue;

    const rSaving = totalMonlyPayment - monthlyRenterExpenses;
    el.rSaving = rSaving;

    const investorNetWorth = prevInvestorNetWorth * periodInterest + rSaving;

    el.investorNetWorth = investorNetWorth;

    if (investorNetWorth < landlordNetWorth && sjjsjs) {
      sjjsjs = false;
      // console.log("MONTH ", el);
      // eslint-disable-next-line no-param-reassign
      payment.MonthWhenBuyingWillBecameBetter = el;
    }
  });
}
