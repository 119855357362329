/* eslint-disable import/prefer-default-export */

/**
 * @param {integer|float} num
 * @returns {string} returned formatted string
 * e.g.: 123456.78 => $123,456
 */
export function formatToMoney(num) {
  return `$${num.toLocaleString("en-US", {
    maximumFractionDigits: 0
  })}`;
}

/**
 * @param {integer|float|string} value
 * @param {string} suff
 * @returns {string} returned formatted string
 * e.g.: (123.45, "%") => 123.45%
 */
export function addSuffix(value, suff) {
  return `${value}${suff || ""}`;
}

/**
 * @param {integer|float|string} value
 * @param {string} pref
 * @returns {string} returned formatted string
 * e.g.: ("$", 123.45) => $123.45
 */
export function addPrefix(pref, value) {
  return `${pref || ""}${value}`;
}

/**
 * @param {integer|float} a
 * @param {integer|float} b
 * @returns {integer|float} result
 */
export function getPercentAsMoney(a, b) {
  let result = (b / 100) * a;
  if (typeof a === "object") result = (b / 100) * parseFloat(a.target.value);
  return result;
}

/**
 * @param {string} str
 * @returns {object} returned formatted string
 */
export function jsonParce(str) {
  const tmp1 = str.replace(/{/g, '{"');
  const tmp2 = tmp1.replace(/:/g, '":');
  const tmp3 = tmp2.replace(/,/g, ',"');
  const json = JSON.parse(tmp3);
  return json;
}

/**
 */
export function getStorage(name) {
  return window.sessionStorage.getItem(name);
}

/**
 */
export function setStorage(name, val) {
  return window.sessionStorage.setItem(name, val);
}

export function getFontSize() {
  let output = 14;
  const resolution = window.innerWidth;
  if (resolution > 767 && resolution < 992) output = 12;
  if (resolution > 576 && resolution < 768) output = 11;
  if (resolution < 575) output = 9;

  return output;
}

export function getHeightCanvas() {
  let output = 150;
  const resolution = window.innerWidth;
  // if (resolution > 767 && resolution < 992) output = 300;
  if (resolution > 576 && resolution < 768) output = 120;
  if (resolution < 575) output = 200;

  return output;
}
