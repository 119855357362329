const configHeaderMenu = {
  headermenu: [
    { id: 1, name: "calculator", link: "#/", addClass: "", icon: 0 },
    { id: 2, name: "payments", link: "#/payments", addClass: "", icon: 1 },
    { id: 3, name: "charts", link: "#/charts", addClass: "", icon: 2 },
    {
      id: 4,
      name: "overview",
      link: "#/overview",
      addClass: "mobile watch-me",
      icon: 3
    }
  ]
};

export default configHeaderMenu;
