/* eslint-disable import/prefer-default-export */
import configTFE from "../../configs/configTextForElements";
import { formatToMoney } from "../../helpers/commonHelpers";
// import analizeForInvestment from "../../lib/investmentComparasion";

const { divElements } = configTFE;
const short = divElements.summaryBlock;

/**
 * @param {object} payment  - data from mortgage.Calculator
 * @param {object} data  - data which input user to <Input>s
 * @returns {object} returned values for <Table >.summary-table
 */
export function usedSets(payment, data) {
  const { mMonthlyExpenses, rMonthlyExpenses } = payment;

  const { rent_or_buy } = data;
  const mTotal = payment.principalAndInterest + mMonthlyExpenses;
  const rTotal = data.rentValue + rMonthlyExpenses;

  const total = Math.max(mTotal, rTotal);
  const mSaving = total - mTotal;
  const rSaving = total - rTotal;

  const columns = [
    { title: short.divEmpty.text, dataIndex: "isTitle", key: "isTitle" },
    { title: short.div1.text, dataIndex: "isRent", key: "isRent" },
    { title: short.div2.text, dataIndex: "isBuy", key: "isBuy" }
  ];

  const monthly_payment = formatToMoney(payment.principalAndInterest);
  const monthly_expenses = formatToMoney(mMonthlyExpenses);
  const monthly_total = formatToMoney(
    mMonthlyExpenses + payment.principalAndInterest
  );
  const monthly_rSaving = formatToMoney(rSaving);
  const monthly_mSaving = formatToMoney(mSaving);

  const dataSource = [
    {
      isTitle: short.div3.text,
      isRent: formatToMoney(data.rentValue),
      isBuy: monthly_payment
    },
    {
      isTitle: short.div4.text,
      isRent: formatToMoney(rMonthlyExpenses),
      isBuy: monthly_expenses
    },
    {
      isTitle: short.div5.text,
      isRent: monthly_rSaving,
      isBuy: monthly_mSaving
    }
  ];

  const arrayCharts2 = [
    { name: "interestPayment", type: "StackedColumn" },
    { name: "principalPayment", type: "StackedColumn" }
  ];
  const arrayList2 = [
    [
      "StackedColumn",
      ["interestPayment", "principalPayment"],
      "Interest Payment & Principal Payment"
    ]
  ];
  return {
    columns,
    dataSource,
    monthly_payment,
    monthly_expenses,
    monthly_total,
    arrayCharts2,
    arrayList2,
    rent_or_buy,
    monthly_rSaving,
    monthly_mSaving
  };
}
