import "./CardComponent.scss";

import { Card, Input, InputNumber, Slider } from "antd";
import PropTypes from "prop-types";
import React from "react";

import { returnFormatter, returnParcer } from "./helpers";

const CardInput = props => {
  const {
    value,
    name,
    prefix,
    suffix,
    min,
    max,
    step,
    onChange,
    //
    onHandleChangeNumeric,
    onAfterChange,
    formattedData
  } = props;

  const marks = {
    [min]: formattedData(min, prefix, suffix),
    [max]: formattedData(max, prefix, suffix)
  };

  function handleClick(e) {
    e.select();
  }

  return (
    <>
      <Card.Grid
        hoverable={false}
        style={{ marginTop: 10 }}
        className="trick-input"
      >
        <InputNumber
          className={`be-input ${prefix.length > 0 ? "left-25" : ""}`}
          name={name}
          value={value}
          min={min}
          max={max}
          step={step}
          formatter={valIn => returnFormatter(valIn, prefix, suffix)}
          parser={valIn => returnParcer(valIn, prefix, suffix)}
          onChange={onHandleChangeNumeric}
          onBlur={() => onAfterChange(value)}
          onClick={e => handleClick(e.target)}
        />
        <Input addonBefore={prefix} addonAfter={suffix} />
      </Card.Grid>
      <Card.Grid hoverable={false} style={{ paddingRight: 10 }}>
        <Slider
          marks={marks}
          tipFormatter={formattedData}
          onAfterChange={() => onAfterChange(value)}
          min={min}
          max={max}
          step={step}
          onChange={onChange}
          value={value}
        />
      </Card.Grid>
    </>
  );
};

export default CardInput;

CardInput.propTypes = {
  name: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onHandleChangeNumeric: PropTypes.func.isRequired,
  onAfterChange: PropTypes.func.isRequired,
  formattedData: PropTypes.func.isRequired
};

CardInput.defaultProps = {
  prefix: "",
  suffix: ""
};
