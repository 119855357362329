import { Button } from "antd";
import PropTypes from "prop-types";
import React from "react";

import configPayments from "../../configs/configPayments";
import configTextForElements from "../../configs/configTextForElements";
import { copyURL } from "../CopyLink/helper";
import { getData } from "./helper";

const CreatePdf = props => {
  const { link, payment } = props;
  const { paymentSummary } = configPayments;
  const { pdfBlock } = configTextForElements;

  return (
    <div className="btn-pdf">
      <Button
        type="primary"
        onClick={() => getData(link, payment, paymentSummary, copyURL(link))}
      >
        {pdfBlock.btn}
      </Button>
    </div>
  );
};

export default CreatePdf;

CreatePdf.propTypes = {
  payment: PropTypes.objectOf(PropTypes.any).isRequired,
  link: PropTypes.objectOf(PropTypes.any).isRequired
};
