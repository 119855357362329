import "../Mortgage.scss";

import { Checkbox } from "antd";
import PropTypes from "prop-types";
import React from "react";

const ShowCheckbox = props => {
  const { id, returnIdCheck, isShow } = props;

  return (
    <Checkbox
      checked={isShow}
      onChange={e => returnIdCheck(e.target.checked, id)}
    />
  );
};

export default ShowCheckbox;

ShowCheckbox.propTypes = {
  id: PropTypes.number.isRequired,
  returnIdCheck: PropTypes.func.isRequired,
  isShow: PropTypes.bool
};

ShowCheckbox.defaultProps = {
  isShow: false
};
