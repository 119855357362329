import "./Payments.scss";

import { Divider, Switch } from "antd";
import { MDBDataTable } from "mdbreact";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import configPayment from "../../configs/configPayments";
import { getStorage, setStorage } from "../../helpers/commonHelpers";
import { getPaymentShedule } from "../../helpers/paymentHelpers";
import useLogic from "../../logic";
import CreatePdf from "../CreatePdf/CreatePdf";
import PaymentsList from "../PaymentsList/PaymentsList";
import ViewChart from "../ViewChart/ViewChart";
import PaymentSummary from "./PaymentSummary";

const Payments = () => {
  const payment = useLogic();
  const { generalData } = configPayment;
  const state_values = useSelector(state => state.state);
  const [kind_of_view, setKindOfView] = useState(getStorage("showPayment"));

  // console.log("state_values", state_values);
  const data = {
    columns: generalData.columns,
    rows: getPaymentShedule(payment.paymentSchedule)
  };

  function onChangeView(checked) {
    setStorage("showPayment", checked ? "table" : "cards");
    setKindOfView(getStorage("showPayment"));
  }

  function defaultChecked() {
    const output = kind_of_view === "cards" ? 0 : 1;
    return output;
  }

  function isTablet() {
    const output = window.innerWidth < 768 ? 1 : "";
    return output;
  }

  return (
    <div className="Payments">
      <div className="wrapper-div">
        <PaymentSummary payment={payment} />
        <CreatePdf payment={payment} link={state_values} />
        <Divider />
        <div className="swich-view-wrapper checkTablet">
          <div>Cards</div>
          <Switch
            className="swich-view"
            defaultChecked={defaultChecked()}
            onChange={onChangeView}
          />
          <div>Table</div>
        </div>
        {(kind_of_view === "cards" || isTablet()) && (
          <PaymentsList data={data} />
        )}
        {kind_of_view !== "cards" && !isTablet() && (
          <MDBDataTable striped bordered small data={data} searching={false} />
        )}
        <Divider />
        <ViewChart
          codes={["investorNetWorth", "landlordNetWorth"]}
          data={payment.paymentSchedule}
          name="Investor Net Worth & Landlord Net Worth"
          type="Line"
        />
      </div>
    </div>
  );
};

export default Payments;
