/* eslint-disable import/prefer-default-export */

/**
 * returns array[{object},{object}]
 * @param {integer} num - length of array [state.mortgageItems]
 * @returns {object} - data for <Table >.antd
 */
export function tableDataSource(num) {
  const result = [];
  for (let i = 0; i < num; i += 1) {
    result.push({ key: i });
  }
  return result;
}

/**
 * returns string, created from object
 * @param {object} obj - one Item from state.mortgageItems.
 * @returns {string}
 */
export function createLink(obj) {
  let result = Object.keys(obj).reduce(function rk(previous, key) {
    return `${previous}${key}:${obj[key]},`;
  }, "{");

  result = `${result.substring(0, result.length - 1)}}|`;
  return result;
}

/**
 * Set prop.disabled for button-link to page "Comparison"
 * @param {array} arr - array of check-boxes
 * @returns {string} "disabled" or ""
 */
export function showBtnComparison(arr) {
  const is_show = arr.reduce(function rk(str, currentItem) {
    return currentItem === true ? str + 1 : str;
  }, 0);
  let result = "disabled";
  if (is_show > 1 && is_show < 3) result = "";
  return result;
}
