import "./Comparison.scss";

import { Button, message } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import { uploadMortgage } from "../../actions";
import configTextForElements from "../../configs/configTextForElements";
import { jsonParce } from "../../helpers/commonHelpers";
import useLogic from "../../logic";
import { copyURL } from "../CopyLink/helper";
import ViewChartComparison from "../ViewChartComparison/ViewChartComparison";
import OneRow from "./OneRow";
import OneRowStatic from "./OneRowStatic";

const Mortgages = props => {
  const { input_data } = props;
  const { copyLinkBlock } = configTextForElements;
  const dispatch = useDispatch();
  const [navigate, setNavigate] = useState(false);

  const [mortgages_on_page, setMortagesOnPage] = useState(input_data);
  useEffect(() => {
    setMortagesOnPage(input_data);
  }, [input_data]);

  const payment1 = useLogic(mortgages_on_page[0]);
  const payment2 = useLogic(mortgages_on_page[1]);
  const mortgages_array = [payment1, payment2];

  function dataForPayment(value) {
    const output = jsonParce(mortgages_on_page[value]);
    dispatch(uploadMortgage(output));
    setNavigate(true);
  }

  function success() {
    message.success(copyLinkBlock.description, 2);
  }

  function copyMortgage(value) {
    const output =
      typeof value === "number"
        ? jsonParce(mortgages_on_page[value])
        : mortgages_on_page;
    console.log("output", value, output);
    const copy_string = copyURL(output);
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(copy_string);
    success();
  }

  function changeMortgage(obj) {
    const is_float =
      parseInt(obj.new_value, 10) === obj.new_value ? "int" : "float";
    const list_mortages = [...mortgages_on_page];
    const item = jsonParce(list_mortages[obj.index]);
    const prop_name = obj.name;
    item[prop_name] =
      is_float === "int"
        ? parseInt(obj.new_value, 0)
        : parseFloat(obj.new_value, 1);
    const json = JSON.stringify(item);
    list_mortages[obj.index] = json.replace(/"/g, "");
    setMortagesOnPage(list_mortages);
  }

  return (
    <>
      <div className="Mortgages ant-table">
        {navigate ? <Redirect to="" /> : null}
        <div className="center-position">
          <Button className="create-link" onClick={() => copyMortgage()}>
            copy Mortgages
          </Button>
        </div>
        <table>
          <thead className="ant-table-thead">
            <tr>
              <th>Mortgage 1</th>
              <th>Mortgage 2</th>
            </tr>
          </thead>
          <tbody className="ant-table-tbody">
            <OneRow
              input_data={mortgages_on_page}
              name_field="goTo"
              func={dataForPayment}
            />
            <OneRow
              input_data={mortgages_on_page}
              name_field="copyMortgage"
              func={copyMortgage}
            />
            <OneRow
              input_data={mortgages_on_page}
              name_field="propertyValue"
              func={changeMortgage}
              title="Property Value"
            />
            <OneRow
              input_data={mortgages_on_page}
              name_field="downpayment"
              func={changeMortgage}
              title="Down Payment"
            />
            <OneRow
              input_data={mortgages_on_page}
              name_field="termMonths"
              func={changeMortgage}
              title="Term Months"
            />
            <tr>
              <td colSpan={2}>
                <ViewChartComparison
                  code="totalPayments"
                  data={[payment1.paymentSchedule, payment2.paymentSchedule]}
                  name="Total Payments"
                  type="Line"
                />
              </td>
            </tr>
            <OneRowStatic
              input_data={mortgages_array}
              name_field="loanAmount"
              title="Loan Amount"
            />
            <OneRowStatic
              input_data={mortgages_array}
              name_field="principalAndInterest"
              title="Principal And Interest"
            />
            <OneRowStatic
              input_data={mortgages_array}
              name_field="mortgageInsurance"
              title="Mortgage Insurance"
            />
            <OneRowStatic
              input_data={mortgages_array}
              name_field="mMonthlyExpenses"
              title="mMonthlyExpenses"
            />
            <OneRowStatic
              input_data={mortgages_array}
              name_field="rMonthlyExpenses"
              title="rMonthlyExpenses"
            />
            <OneRowStatic
              input_data={mortgages_array}
              name_field="total"
              title="Total"
            />
            <OneRow
              input_data={mortgages_array}
              name_field="pdfAction"
              func={changeMortgage}
              origin={mortgages_on_page}
            />
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Mortgages;

Mortgages.propTypes = {
  input_data: PropTypes.arrayOf(PropTypes.string).isRequired
};
