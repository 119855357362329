/* eslint-disable react/forbid-prop-types */
import PropTypes from "prop-types";
import React from "react";
import { Bar, Line } from "react-chartjs-2";

import configChart from "../../configs/configChart"; // all possibile color and codes
import {
  formatToMoney,
  getFontSize,
  getHeightCanvas
} from "../../helpers/commonHelpers";
import { xYear } from "../CardComponent/helpers";
import { getData, getDatasets } from "./helper";

const ViewChart = props => {
  const { type, data, codes, name } = props;
  const { name_fields, chart_colors } = configChart;

  const data2 = {
    labels: getData(data, codes[0]).date,
    datasets: getDatasets(data, codes, name_fields, chart_colors)
  };

  /**
   * Sorry for russian.
   * подключаем тип диаграммы
   * @param {string} type_chart
   * @param {object} values - format: {labels: [1, 2, 3, 4, ...], datasets: [{…}, {…}, ...]}
   * labels берутся из getData(), datasets берутся из getDatasets()
   * @param {object} options - настройки отображения графика
   */
  function selectedChart(type_chart, values, options) {
    const object_charts = {
      Line: <Line data={values} height={getHeightCanvas()} options={options} />,
      Bar: <Bar data={values} height={getHeightCanvas()} options={options} />
    };
    return object_charts[type_chart];
  }

  const options = {
    title: {
      display: true,
      text: name
    },
    scales: {
      yAxes: [
        {
          ticks: {
            padding: 5,
            fontSize: getFontSize(),
            callback(value) {
              return formatToMoney(value);
            }
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            minRotation: 0.00001,
            padding: 5,
            fontSize: getFontSize(),
            labelOffset: 15,
            callback(value, index) {
              if (index % 5 !== 0) return "";
              return `${xYear(value)}`;
            }
          }
        }
      ]
    },
    tooltips: {
      callbacks: {
        label(tooltipItem, inpit_data) {
          const dataset = inpit_data.datasets[tooltipItem.datasetIndex];
          const currentValue = dataset.data[tooltipItem.index];
          return `${formatToMoney(currentValue)}`;
        },
        title(tooltipItem, inpit_data) {
          return xYear(inpit_data.labels[tooltipItem[0].index]);
        }
      }
    }
  };

  return (
    <div className="wrapper-canvas">{selectedChart(type, data2, options)}</div>
  );
};

export default ViewChart;

ViewChart.propTypes = {
  data: PropTypes.array.isRequired,
  codes: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string
};

ViewChart.defaultProps = {
  name: ""
};
