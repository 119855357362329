/* eslint-disable import/prefer-default-export */
import { addSuffix, formatToMoney } from "../../helpers/commonHelpers";
import { getDay } from "../../helpers/paymentHelpers";
/**
 * @param {object} data - element from state.state
 * @returns {object} returned formatted data
 */
export function saveItemMortgage(data) {
  const output = {};
  Object.keys(data).forEach(key => {
    // if (typeof data[key] === "number" && data[key] !== null) {
    output[key] = data[key];
    // }
  });
  return output;
}

/**
 * @param {object} item - element from state.mortgageItems
 * @returns {string}  - name of Mortgage
 */
export function getMortgageName(item) {
  const {
    create_date,
    propertyValue,
    mortgageRateValue,
    amortizationValue
  } = item;
  const day = getDay(create_date[0]);
  const amount = formatToMoney(propertyValue);
  const interest = addSuffix(mortgageRateValue, "%");
  const amortization = addSuffix(amortizationValue, "years");
  return `${day}: ${amount} - ${interest} - ${amortization}`;
}

export function getNewRow(timestamp) {
  const current = new Date().getTime() / 1000;
  const class_name = current - timestamp < 10 ? "new-row" : "";
  return class_name;
}
