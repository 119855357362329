import "../Mortgage.scss";
import "./ShowOneMortgage.scss";

import { Button, message, Modal } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { toggleDrawer, uploadMortgage } from "../../../actions";
import { getMortgageName, getNewRow } from "../helpers";

const ShowOneMortgage = props => {
  const { id } = props;
  const mortgage_items = useSelector(state => state.mortgageItems);
  const [navigate, setNavigate] = useState(false);

  const dispatch = useDispatch();
  const [visible, toggleVisible] = useState(false);

  /**
   * @see ant.message
   * @param {string} callback
   */
  function success() {
    message.success("Mortgage upload successfully", 2);
  }

  /**
   * @see ant.modal
   * @param {string} callback
   */
  function showConfirm() {
    toggleVisible(!visible);
  }

  /**
   * @param {integer} num - [state.mortgageItems{num}]
   * @param {uploadMortgage} callback - dispatch callback - set value state.state.
   */
  function toUploadMortgage(num) {
    const new_values = mortgage_items.filter((_number, index) => index === num);
    dispatch(uploadMortgage(new_values[0]));
    showConfirm();
    success();
    setNavigate(true);
    dispatch(toggleDrawer(false));
  }

  return (
    <div className="Mortgage">
      {navigate ? <Redirect to="" /> : null}
      <Button
        className={getNewRow(mortgage_items[id].create_date[0] / 1000)}
        type="link"
        onClick={showConfirm}
      >
        {getMortgageName(mortgage_items[id])}
      </Button>
      <Modal
        title="Upload data from mortgage?"
        visible={visible}
        onOk={() => toUploadMortgage(id)}
        onCancel={() => showConfirm()}
        okText="YES"
        cancelText="NO"
      >
        <h3>{getMortgageName(mortgage_items[id])}</h3>
      </Modal>
    </div>
  );
};

export default ShowOneMortgage;

ShowOneMortgage.propTypes = {
  id: PropTypes.number.isRequired
};
