/* eslint-disable jsx-a11y/click-events-have-key-events */
import "./ComparisonTable.scss";

import { Button, Table, Typography } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { toggleDrawer } from "../../actions";
import configTFE from "../../configs/configTextForElements";
import DeleteMortgage from "../Mortgage/DeleteMortgage/DeleteMortgage";
import ShowCheckbox from "../Mortgage/ShowCheckbox/ShowCheckbox";
import ShowOneMortgage from "../Mortgage/ShowOneMortgage/ShowOneMortgage";
import { createLink, showBtnComparison, tableDataSource } from "./helper";

const ComparisonTable = () => {
  const dispatch = useDispatch();
  const { drawerBlock } = configTFE;
  const { Text } = Typography;
  const mortgage_items = useSelector(state => state.mortgageItems);
  const [selected_checkboxes, setSelectedCheckboxes] = useState([]);
  const [mortgage_to_url, setMortgageToUrl] = useState("");
  const [navigate, setNavigate] = useState(false);

  /**
   * returns string with couples key:value for URL
   * @param {array} basic - state.mortgageItems.
   * @param {array} condition - selected_checkboxes.
   * @param {setMortgageToUrl()} callback - set paramenetrs in URL after "?"
   */
  function prepareUrl(basic, condition) {
    const str_url = basic.reduce((str, currentItem, index) => {
      return condition[index] === true
        ? `${str}${createLink(basic[index])}`
        : str;
    }, "");
    setMortgageToUrl(str_url);
    setNavigate(true);
    dispatch(toggleDrawer(false));
    setSelectedCheckboxes([]);
  }

  /**
   * editing array with checked items from data.mortgageItems
   * @param {boolean} e - prop of checkbox
   * @param {integer} id - number of selected element from [data.mortgageItems]
   * @param {setSelectedCheckboxes()} callback - return changed list with selected items
   */
  function editArraySelect(e, id) {
    const selected_items = [...selected_checkboxes];
    selected_items[id] = e;
    setSelectedCheckboxes(selected_items);
  }

  const columns = [
    {
      title: "",
      key: "checkbox",
      dataIndex: "checkbox",
      render: (_, record) => {
        return (
          <ShowCheckbox
            id={record.key}
            returnIdCheck={editArraySelect}
            isShow={selected_checkboxes[record.key]}
          />
        );
      }
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (_, record) => {
        return <ShowOneMortgage id={record.key} />;
      }
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => {
        return <DeleteMortgage id_mortgage={record.key} />;
      }
    }
  ];

  return (
    <div className="ComparisonTable paginator-off">
      <Button
        className={showBtnComparison(selected_checkboxes)}
        onClick={() => prepareUrl(mortgage_items, selected_checkboxes)}
      >
        {navigate ? <Redirect to={`comparison?${mortgage_to_url}`} /> : null}
        {drawerBlock.title4.text}
      </Button>
      <div>
        <Text>{drawerBlock.title5.text}</Text>
      </div>
      <Table
        columns={columns}
        dataSource={tableDataSource(mortgage_items.length)}
      />
    </div>
  );
};

export default ComparisonTable;
