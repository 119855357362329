import { UPLOAD_MORTGAGE } from "./types";

export default function uploadMortgage(obj) {
  console.log("uploadMortgage():", obj);
  return {
    type: UPLOAD_MORTGAGE,
    payload: {
      obj
    }
  };
}
