import { Button, message } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import configTextForElements from "../../configs/configTextForElements";
import { copyURL } from "./helper";

const CopyLink = props => {
  const { title } = props;
  const { copyLinkBlock } = configTextForElements;
  const data_state_state = useSelector(state => state.state);

  function success() {
    message.success(copyLinkBlock.description, 2);
  }

  /**
   * @param {string} txt - title of button
   * @returns {string} returned string or default
   */
  function showTitle(txt) {
    const res = txt !== "" ? txt : copyLinkBlock.btn;
    return res;
  }

  /**
   *
   * @param {*} data
   */
  function clickHandler(data) {
    const output = copyURL(data);
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(output);
    success();
  }

  return (
    <Button
      type="primary"
      className="copy-link"
      onClick={() => clickHandler(data_state_state)}
    >
      {showTitle(title)}
    </Button>
  );
};

export default CopyLink;

CopyLink.propTypes = {
  title: PropTypes.string
};
CopyLink.defaultProps = {
  title: ""
};
