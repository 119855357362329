const configPayments = {
  arrayMonths: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],
  paymentSummary: [
    "Property Value",
    "Down Payment",
    "Loan Amount",
    "Total",
    "Term Years",
    "mMonthlyExpenses",
    "rMonthlyExpenses"
  ],
  generalDataTitle: [
    { header: "Month", dataKey: "month" },
    { header: "Interest Payment", dataKey: "interestPayment" },
    { header: "Total Interest", dataKey: "totalInterest" },
    { header: "Principal Payment", dataKey: "principalPayment" },
    { header: "Total Payment", dataKey: "totalPayment" },
    { header: "Total Payments", dataKey: "totalPayments" },
    { header: "Balance", dataKey: "balance" },
    { header: "Landlord Net Worth", dataKey: "landlordNetWorth" },
    { header: "Investor Net Worth", dataKey: "investorNetWorth" }
  ],
  generalData: {
    columns: [
      {
        label: "Month",
        field: "month",
        sort: "disabled"
      },
      {
        label: "Interest Payment",
        field: "interestPayment",
        sort: "disabled"
      },
      {
        label: "Total Interest",
        field: "totalInterest",
        sort: "disabled"
      },
      {
        label: "Principal Payment",
        field: "principalPayment",
        sort: "disabled"
      },
      {
        label: "Total Payment",
        field: "totalPayment",
        sort: "disabled"
      },
      {
        label: "Total Payments",
        field: "totalPayments",
        sort: "disabled"
      },
      {
        label: "Balance",
        field: "balance",
        sort: "disabled"
      },
      {
        label: "Landlord Net Worth",
        field: "landlordNetWorth",
        sort: "disabled"
      },
      {
        label: "Investor Net Worth",
        field: "investorNetWorth",
        sort: "disabled"
      }
    ]
  }
};

export default configPayments;
