import { TOGGLE_DRAWER } from "./types";

export default function toggleDrawer(value) {
  return {
    type: TOGGLE_DRAWER,
    payload: {
      value
    }
  };
}
