/* eslint-disable no-case-declarations */
import { ADD_MORTGAGE, DELETE_MORTGAGE } from "../actions/types";

export default (state_mortgage = [], action) => {
  switch (action.type) {
    case ADD_MORTGAGE:
      const newState = [...state_mortgage, action.payload.obj];
      return newState;
    case DELETE_MORTGAGE:
      const new_array = state_mortgage.filter(
        (_number, index) => index !== action.payload.num
      );
      return new_array;
    default:
      return state_mortgage;
  }
};
