import "./LeftPart.scss";

import { DownOutlined, LineChartOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Collapse, Switch } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setValue } from "../../actions";
import config from "../../configs/configCards";
import configTFE from "../../configs/configTextForElements";
import { addSuffix, formatToMoney } from "../../helpers/commonHelpers";
import useLogic from "../../logic";
import CardComponentLogic from "../CardComponent/CardComponentLogic";
import TextPanel from "../TextPanel/TextPanel";
import { getBorrowing, getFullDescription, getPerMonth } from "./helper";

const LeftPart = () => {
  const { Panel } = Collapse;
  const { divElements } = configTFE;

  const [active_keys_first, setActiveKeysFirst] = useState([1]);
  const [active_keys_second, setActiveKeysSecond] = useState([2]);
  const texts = ["Expand all", "Collapse all"];
  const [show_text_collapse_all, setShowTextCollapseAll] = useState(1);
  const state = useSelector(s => s.state);
  const dispatch = useDispatch();
  const compare = state.rent_or_buy;
  const setCompare = v => {
    dispatch(setValue("rent_or_buy", v));
  };

  function toggleTabsView(num) {
    console.log("Collapse", num);
    let arr1 = [];
    let arr2 = [];
    if (num === 0) {
      arr1 = ["1", "2", "3"];
      arr2 = ["1", "2", "3", "4", "5", "6"];
      setShowTextCollapseAll(1);
    } else {
      setShowTextCollapseAll(0);
    }
    setActiveKeysFirst(arr1);
    setActiveKeysSecond(arr2);
  }

  function fCollapse(key) {
    console.log(key);
    setActiveKeysFirst(key);
    setShowTextCollapseAll(1);
  }

  function sCollapse(key) {
    console.log(key);
    setActiveKeysSecond(key);
    setShowTextCollapseAll(1);
  }

  const payment = useLogic();

  return (
    <div className="LeftPart div-wrapper">
      <div className="expand_all">
        <Button
          type="link"
          icon={show_text_collapse_all ? <UpOutlined /> : <DownOutlined />}
          onClick={() => toggleTabsView(show_text_collapse_all)}
        >
          <div>{texts[show_text_collapse_all]}</div>
        </Button>
      </div>
      <form>
        <Collapse activeKey={active_keys_first} onChange={fCollapse}>
          <Panel
            header={divElements.titles.title1.text}
            extra={<>Borrowing: {getBorrowing(state)}</>}
            key="1"
          >
            <CardComponentLogic name="propertyValue" />
            <CardComponentLogic name="downPaymentValue" />
          </Panel>
          <Panel
            header={divElements.titles.title2.text}
            extra={
              <>
                {getFullDescription(
                  state.amortizationValue,
                  "amortizationValue"
                )}
                {"   "}
                {getFullDescription(
                  state.mortgageRateValue,
                  "mortgageRateValue"
                )}
              </>
            }
            key="2"
          >
            <TextPanel num="2" />
            <CardComponentLogic name="amortizationValue" />
            <CardComponentLogic name="mortgageRateValue" />
          </Panel>
          <Panel
            header={divElements.titles.title5.text}
            extra={`${formatToMoney(payment.mMonthlyExpenses)} per month`}
            key="3"
          >
            <TextPanel num="5" />
            <CardComponentLogic name="amountAnnualTaxesValue" />
            <CardComponentLogic name="maintenanceValue" />
            <CardComponentLogic name="ownerInsuranceValue" />
            <CardComponentLogic name="annualHeatingCostsValue" />
          </Panel>
        </Collapse>
        <div
          className="ant-collapse-header"
          style={{ textAlign: "center", padding: "10px 0", margin: "10px 0" }}
        >
          <span style={{ margin: 10 }}>Compare to rent the same property</span>
          <Switch checked={compare} onChange={checked => setCompare(checked)} />
        </div>

        <div
          onClick={() => {
            setCompare(true);
          }}
        >
          <Collapse
            style={{ opacity: compare ? "1.0" : "0.33" }}
            activeKey={active_keys_second}
            onChange={sCollapse}
          >
            <Panel
              header={config.rentValue.title}
              extra={getPerMonth(state.rentValue)}
              key="1"
            >
              <CardComponentLogic name="rentValue" />
              <CardComponentLogic name="rentAnnualIncreaseValue" />
            </Panel>
            <Panel
              header={config.rentersInsuranceValue.title}
              extra={getPerMonth(state.rentersInsuranceValue)}
              key="2"
            >
              <CardComponentLogic name="rentersInsuranceValue" />
            </Panel>
            <Panel
              header={
                <>
                  {config.returnInvestmentValue.title}{" "}
                  <LineChartOutlined style={{ color: "green" }} />
                </>
              }
              extra={addSuffix(state.returnInvestmentValue, " %")}
              key="3"
            >
              <TextPanel num="7" />
              <CardComponentLogic name="returnInvestmentValue" />
            </Panel>
            <Panel
              header={
                <>
                  {divElements.titles.title6.text}{" "}
                  <LineChartOutlined style={{ color: "green" }} />
                </>
              }
              extra={addSuffix(state.rateOfGrowthValue, " %")}
              key="4"
            >
              <TextPanel num="6" />
              <CardComponentLogic name="rateOfGrowthValue" />
            </Panel>
            <Panel
              header={divElements.titles.title4.text}
              extra={formatToMoney(state.buyingHomeValue)}
              key="5"
            >
              <TextPanel num="6" />
              <CardComponentLogic name="buyingHomeValue" />
              <CardComponentLogic name="sellingHomeValue" />
            </Panel>
          </Collapse>
        </div>
      </form>
    </div>
  );
};

export default LeftPart;
